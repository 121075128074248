import {Popover} from '@mui/material';
import {useEffect, useState} from 'react';
import {LocationInfoDisplay} from '../model/LocationInfoDisplay';
import GeneralStatistics from './GeneralStatistics';


interface LocationInfoProps {
  locationInfoDisplay: LocationInfoDisplay | undefined
}

export default function LocationInfo({locationInfoDisplay}: LocationInfoProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(Boolean(locationInfoDisplay))
  }, [locationInfoDisplay])

  return (
    <div>
      <Popover
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={locationInfoDisplay?.anchorElement}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        {locationInfoDisplay?.locationMeasurementResponse &&
            <GeneralStatistics stats={locationInfoDisplay?.locationMeasurementResponse.generalStatistics}/>
        }
      </Popover>
    </div>
  );
}