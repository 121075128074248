import {GeneralStatisticsModel} from '../model/GeneralStatisticsModel';
import {Grid, LinearProgress, Paper, Typography} from '@mui/material';
import React, {Fragment, useEffect, useState} from 'react';
import {LatLngBounds, LatLngBoundsExpression} from 'leaflet';

interface GeneralStatisticsProps {
  stats: null | GeneralStatisticsModel
}

function GeneralStatistics({stats}: GeneralStatisticsProps) {
  const rows = [
    {
      label: 'Total installed power',
      value: (stats: GeneralStatisticsModel) => toGw(stats?.totalInstalledPower) + ' GW'
    },
    {
      label: 'Current output',
      value: (stats: GeneralStatisticsModel) => toGw(stats?.currentPowerOutput) + ' GW'
    },
    {
      label: 'Windparks / Locations',
      value: (stats: GeneralStatisticsModel) => stats?.totalLocations.toString()
    },
    {
      label: 'Distinct turbine types',
      value: (stats: GeneralStatisticsModel) => stats?.distinctTurbineTypes.toString()
    },
    {
      label: 'Turbine types with power profile',
      value: (stats: GeneralStatisticsModel) => stats?.turbineTypesWithPowerProfile.toString()
    },
    {
      label: 'Installed turbines',
      value: (stats: GeneralStatisticsModel) => stats?.totalInstalledWindTurbines.toString()
    },
    {
      label: 'Installed turbines with power profile',
      value: (stats: GeneralStatisticsModel) => stats?.installedWindTurbinesWithPowerProfile.toString()
    },
    {
      label: 'Turbines running',
      value: (stats: GeneralStatisticsModel) => stats?.turbinesRunning.toString()
    },
    {
      label: 'Turbines not cut in',
      value: (stats: GeneralStatisticsModel) => stats?.turbinesNotCutIn.toString()
    },
    {
      label: 'Turbines cut out',
      value: (stats: GeneralStatisticsModel) => stats?.turbinesCutOut.toString()
    }
  ];

  function percentageOutput() {
    if (!stats) {
      return 0;
    } else {
      return stats.currentPowerOutput * 100 / stats.totalInstalledPower;
    }
  }

  function toGw(input: number | undefined): number {
    return input ? Math.round(input / 1000000) / 1000 : 0;
  }

  function renderStatistics() {
    return stats ? rows.map(it => (
      <Fragment>
        <Grid xs={6}>
          <Typography variant="body2">{it.label}</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography variant="body2">{it.value(stats)}</Typography>
        </Grid>
      </Fragment>
    )) : (<div></div>);
  }

  return (
    <div>
      <Paper variant="outlined">
        <Grid container spacing={0} sx={{width: '250px'}}>
          {renderStatistics()}
          <Grid xs={12}>
            <LinearProgress variant="determinate" value={percentageOutput()} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default GeneralStatistics;