import {Marker} from 'react-leaflet';
import React from 'react';
import {Location} from '../model/WindPowerOutput';
import {Popover} from '@mui/material';
import {LeafletEventHandlerFnMap, LeafletMouseEvent} from 'leaflet';
import {LocationInfoDisplay} from '../model/LocationInfoDisplay';

interface EeMarkerProps {
  location: Location;
  clickCallback: (source: LocationInfoDisplay) => void
}

export default function EeMarker({location, clickCallback}: EeMarkerProps) {
  const sdf: LeafletEventHandlerFnMap = {
    click: (event: LeafletMouseEvent) => {
      clickCallback({
        anchorElement: event.originalEvent.target as Element,
        location: location
      })
    }
  }

  return (
    <div>
      <Marker position={[location.latitude, location.longitude]} eventHandlers={sdf} />
    </div>
  )
}