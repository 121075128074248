import React, {useEffect, useState} from 'react';
import './App.css';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import {WindPowerOutput, Location} from './model/WindPowerOutput';
import GeneralStatistics from './component/GeneralStatistics';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {GeneralStatisticsModel} from './model/GeneralStatisticsModel';
import {Box, Menu, Popover, Popper} from '@mui/material';
import {LatLngBounds, LatLngBoundsExpression} from 'leaflet';
import EeMarker from './component/EeMarker';
import LocationInfo from './component/LocationInfo';
import {LocationInfoDisplay} from './model/LocationInfoDisplay';
import {LocationMeasurementResponse} from './model/LocationMeasurementResponse';

const mapBounds: LatLngBoundsExpression = new LatLngBounds([53.108, 10.595], [54.699, 14.421]);

function App() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [windPowerOutput, setWindPowerOutput] = useState<WindPowerOutput>({locations: []});
  const [generalStatistics, setGeneralStatistics] = useState<null | GeneralStatisticsModel>(null);
  const [locationInfoDisplay, setLocationInfoDisplay] = useState<LocationInfoDisplay | undefined>(undefined);


  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/general-statistics`)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoading(false);
            setIsLoaded(true);
            setGeneralStatistics(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoading(false);
            setIsLoaded(true);
            setError(error);
          }
        );

      fetch(`${process.env.REACT_APP_API_URL}/wind-power-output`)
        .then(res => res.json())
        .then(
          (result) => {
            // setIsLoading(false);
            // setIsLoaded(true);
            setWindPowerOutput(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            // setIsLoading(false);
            // setIsLoaded(true);
            setError(error);
          }
        );
    }

  }, []);

  function fetchGeneralLocationMeasurement(locationInfoDisplay: LocationInfoDisplay) {
    fetch(`${process.env.REACT_APP_API_URL}/location/${locationInfoDisplay.location.id}/latest-measurement`)
      .then(res => res.json())
      .then(
        (result) => {
          locationInfoDisplay.locationMeasurementResponse = result
          setLocationInfoDisplay(locationInfoDisplay);
        },
        (error) => {
          setError(error);
        }
      );
  }

  function renderMarker(location: Location) {
    return (<EeMarker location={location} clickCallback={(lid) => fetchGeneralLocationMeasurement(lid)}/>);
  }

  return (
    <div className="App">
      <CssBaseline>
        <Box sx={{width: '250px', zIndex: 'modal', position: 'absolute', right: 0}}>
          <GeneralStatistics stats={generalStatistics}/>
        </Box>
        <LocationInfo locationInfoDisplay={locationInfoDisplay}/>
        <MapContainer style={{width: '100%', height: '100vh'}} bounds={mapBounds} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            windPowerOutput.locations.map(location => renderMarker(location))
          }
        </MapContainer>
      </CssBaseline>
    </div>
  );
}

export default App;
